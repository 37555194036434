import { LoadingBuzz } from '@admin-portal-shared-components/spinner';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const PocInformation = lazy(() => import('./components/pages/pocInformation/PocInformation'));

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingBuzz data-testid="transition-spinner" />}>
        <Routes>
          <Route path="/" element={<PocInformation />} />
          <Route path="/beescare/ticket-tools" element={<PocInformation />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
